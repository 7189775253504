.ant-form-vertical .ant-form-item-label {
  padding-bottom: 2px;
}

.ant-page-header {
  padding: 0 0 1rem;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.modal-gw-customer-product-edit {
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 4px 8px;
}

.modal-gw-customer-edit {
  @media only screen and (min-width: 768px) {
    min-width: 680px !important;
  }

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  .ant-form-item {
    margin-bottom: 12px;
  }
}

.ant-card-body {
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
}

.ant-form-item .ant-input-number {
  width: 100%;
}

.ant-tag {
  border-radius: 22px;
}

.ant-input,
.ant-picker,
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-input-number,
.ant-input-number-input {
  border-radius: 6px !important;
}

.ant-input-number-handler-up {
  border-top-right-radius: 6px;
}

.ant-input-number-handler-down {
  border-bottom-right-radius: 6px;
}

.ant-input-number-handler-wrap {
  border-radius: 0 6px 6px 0;
}

.ant-input-group {
  .ant-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .ant-btn {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
}

.ant-menu-inline {
  border-right: 0 !important;
}

.ant-menu-item-group {
  margin: 8px 0 !important;
}

.ant-menu {
  .ant-menu-item {
    border-radius: 6px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: auto;


    &:hover {
      background-color: #f0f0f0;
    }

    &::after {
      display: none;
    }
  }
}

.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  border-radius: 6px;
  background-color: transparent;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 4px !important;

  &.ant-menu-submenu-open {
    background-color: #fafafa;
  }

  .ant-menu-sub {
    background-color: transparent;
  }

  .ant-menu-submenu-title {
    margin-bottom: 0 !important;
    padding-left: 26px !important;

    @media only screen and (max-width: 767px) {
      padding-left: 12px !important;
    }
  }

  .ant-menu-item {
    margin-bottom: 4px !important;

    &:hover {
      background-color: #eaeaf1;
    }
  }
}

.ant-menu-item-selected {
  background-color: #f0f7ff !important;
}

.ant-layout-sider-zero-width-trigger {
  font-size: 14px !important; /* Ubah ukuran ikon */
  top: 0 !important; /* Posisi tombol */
  right: -24px !important; /* Posisi tombol */
  width: 24px !important; /* Lebar tombol */
}
