#auth-social-callback-page{
  .auth-social-callback-wrapper{
    text-align: center;
    .vui-content-loading{
      min-height: 80px;
      .vui-spin.ant-spin-lg .ant-spin-dot{
        font-size: 32px;
      }
    }
  }
}

@primary-color: #041242;@secondary-color: #010316;@font-family: Roboto, sans-serif;@screen-xs: 480px;@screen-sm: 576px;@screen-md: 768px;@screen-lg: 992px;@screen-xl: 1200px;@screen-xs-min: 0px;@screen-sm-min: 480px;@screen-md-min: 576px;@screen-lg-min: 768px;@screen-xl-min: 992px;