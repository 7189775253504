.table-action {
  .btn-action {
    border: 1px solid;
    padding: 6px 14px;

    &.view {
      border-color: #162039;
      svg {
        fill: #162039;
      }
    }

    &.delete {
      border-color: #ff4d4f;
      svg {
        fill: #ff4d4f;
      }
    }
  }

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-child {
    border-left-width: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.single-action {
    .btn-action {
      border-left-width: 1px !important;
      border-radius: 4px !important;
    }
  }
}

@primary-color: #041242;@secondary-color: #010316;@font-family: Roboto, sans-serif;@screen-xs: 480px;@screen-sm: 576px;@screen-md: 768px;@screen-lg: 992px;@screen-xl: 1200px;@screen-xs-min: 0px;@screen-sm-min: 480px;@screen-md-min: 576px;@screen-lg-min: 768px;@screen-xl-min: 992px;