.main-header-dropdown {

  .ant-dropdown-menu {
    min-width: 200px;
    border: 1px solid rgba(72, 94, 144, 0.16);
  }

  .ant-dropdown-menu-title-content {
    font-size: 13px;

    a {
      display: flex;
      align-items: center;
    }

    .icon {
      margin-right: 10px;
    }
  }

  .avatar-menu-item {
    padding: 0px;
  }

  .avatar-wrapper {
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
  }
}

.main-menu-component, .side-menu {
  height: 100%;

  .dropdown-main-menu-item {
    &.ant-menu-item-selected {
      background: rgb(#023e7d, .1) !important;
    }

    &:active {
      background: transparent;
    }
  }

  .ant-menu-item-group-title {
    color: rgba(0, 0, 0, 0.30);
  }
}

.main-menu-component {
  padding-top: 1em !important;

  .ant-menu-item-group {
    margin: 1em 0;
  }
}

.content-menu {
  background: #fff;
  padding: 0 2em;
}

.dropdown-main-menu {
  min-width: 200px !important;
  border: 1px solid rgba(72, 94, 144, 0.16);

  .ant-menu {
    padding: 10px 0px !important;

    li {
      height: 30px;
      line-height: 30px;
      margin: 0px !important;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .dropdown-main-menu-item {
    &.ant-menu-item-selected {
      background: rgb(#023e7d, .1) !important;
    }

    &:active {
      background: transparent;
    }
  }
}

.vui-header {
  display: grid;
  grid-template-columns: 225px 6fr 2fr;
  grid-template-rows: 60px;
  align-items: center;
  background: white;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  padding: 0 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .menu-icon {
    margin-right: 20px;
    display: none;
  }

  .brand-wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    .logo {
      height: 20px;
      width: auto;
      padding-left: 10px;
    }
  }

  .navigation-wrapper {
    height: 100%;

    .ant-menu {
      height: 100%;
      line-height: unset;
      border-bottom: none;

      li, .ant-menu-submenu-title {
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 500;
      }
    }
  }

  .profile-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .auth-wrapper {
      height: 100%;
      margin-left: 15px;

      .auth-spaces, .ant-space-item, .ant-dropdown-trigger {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .ant-dropdown-trigger {
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    .brand-wrapper {
      display: flex;
      align-items: center;
      border-right: 0;

      .logo {
        height: 26px;
        width: auto;
      }
    }

    .navigation-wrapper {
      display: none;
    }

    .menu-icon {
      display: block;
    }
  }
}

@primary-color: #041242;@secondary-color: #010316;@font-family: Roboto, sans-serif;@screen-xs: 480px;@screen-sm: 576px;@screen-md: 768px;@screen-lg: 992px;@screen-xl: 1200px;@screen-xs-min: 0px;@screen-sm-min: 480px;@screen-md-min: 576px;@screen-lg-min: 768px;@screen-xl-min: 992px;