.vui-page-title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 25px;

  .left-section {
    display: flex;
    grid-gap: 16px;
    align-items: center;
    a {
      color: #000;
    }
    .anticon-left-circle {
      font-size: 28px;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;

    .right-section {
      width: 100%;

      .btn-wrapper {
        display: grid;
        grid-template-columns: 1fr;

        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}

@primary-color: #041242;@secondary-color: #010316;@font-family: Roboto, sans-serif;@screen-xs: 480px;@screen-sm: 576px;@screen-md: 768px;@screen-lg: 992px;@screen-xl: 1200px;@screen-xs-min: 0px;@screen-sm-min: 480px;@screen-md-min: 576px;@screen-lg-min: 768px;@screen-xl-min: 992px;